/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatten } from "flat";

/**
 * Flattens nested objects and transforms values to string
 * @param obj input object
 * @returns string record
 */
export const flattenToRecord = (obj?: any): Record<string, string> => {
    if (obj) {
        const objFlattened = flatten(obj) as any;
        return Object.keys(objFlattened).reduce((acc, key) => {
            acc[key] = String(objFlattened[key]);
            return acc;
        }, {} as Record<string, string>);
    }
    return {};
};

export const isEqual = (obj1: object, obj2: object) => {
    if (Object.keys(obj1).length === Object.keys(obj2).length) {
        return Object.keys(obj1).every((key) => Object.hasOwn(obj2, key) && obj2[key] === obj1[key]);
    }
    return false;
};
