import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { faro } from "@grafana/faro-react";
import { ErrorModel } from "../../models/ErrorModel";
import { flattenToRecord } from "../../helpers/ObjectHelper";
import { appActions } from "../reducers/AppSlice";
import { organizationApi } from "../services/OrganizationApi";

const CUSTOM_ERROR_HANDLING_ENDPOINTS = [
    organizationApi.endpoints.createGroups.name,
    organizationApi.endpoints.addLocationsToGroup.name,
    organizationApi.endpoints.editGroupName.name,
    organizationApi.endpoints.editGroupParent.name,
    organizationApi.endpoints.deleteGroupLocations.name,
    organizationApi.endpoints.createGroups.name,
];

const errorMiddleware: Middleware =
    ({ dispatch }: MiddlewareAPI) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action)) {
            const error: ErrorModel = {
                title: action.error?.data?.message,
                timestamp: new Date().getTime(),
                status: action.payload.status,
            };

            // add more details to error reported to Faro
            const context = flattenToRecord(action.payload);
            faro.api.pushError(new Error(error.title), { context });

            const hasCustomHandling = CUSTOM_ERROR_HANDLING_ENDPOINTS.includes(action.meta.arg.endpointName);
            if (!hasCustomHandling) {
                dispatch(appActions.setAppError(error));
            }
        }
        return next(action);
    };

export default errorMiddleware;
